import * as React from "react";

import Layout from "../components/layout";
import SecondaryClouds from "../components/secondary-clouds";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { withAssetPrefix } from "gatsby";

import { graphql } from "gatsby";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import BlurredImage from "../components/blurred-image";

const Pricing = ({ data }) => {
  return (
    <Layout>
      <HelmetDatoCms seo={data.about.seoMetaTags} />
      <main className="about-us">
        <section className="about-us-page">
          <div className="about-sidebar-absolute">
            <div className="about-sidebar">
              <a
                href={`#${data.about.aboutUsSlug}`}
                dataName={data.about.aboutUsSlug}
                className="sidebar-link js-sidebar-link active"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span class="sidebar-link-text">{data.about.aboutUsTitle}</span>
              </a>
              <a
                href={`#${data.about.foundersSlug}`}
                dataName={data.about.foundersSlug}
                className="sidebar-link js-sidebar-link"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span class="sidebar-link-text">
                  {data.about.foundersTitle}
                </span>
              </a>
              <a
                href={`#${data.about.missionStatementSlug}`}
                dataName={data.about.missionStatementSlug}
                className="sidebar-link js-sidebar-link"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span class="sidebar-link-text">
                  {data.about.missionStatementTitle}
                </span>
              </a>
              <a
                href={`#${data.about.pressLinksSlug}`}
                dataName={data.about.pressLinksSlug}
                className="sidebar-link js-sidebar-link"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span class="sidebar-link-text">
                  {data.about.pressLinksTitle}
                </span>
              </a>
            </div>
          </div>
          <section
            className="about-section about-us-section js-about-section"
            id={data.about.aboutUsSlug}
          >
            <h1>{data.about.aboutUsTitle}</h1>
            <ReactMarkdown
              className="about-markdown"
              remarkPlugins={[remarkGfm]}
            >
              {data.about.aboutUsBody}
            </ReactMarkdown>
          </section>
          <section
            className="about-section founders-section js-about-section"
            id={data.about.foundersSlug}
          >
            <div className="founders-grid">
              {data.about.founder.map((founder) => (
                <div key={founder.id} className="founders-item">
                  <BlurredImage image={founder.image} />
                  <span class="founder-role">{founder.role}</span>
                  <h4>{founder.name}</h4>
                  <ReactMarkdown
                    className="about-markdown"
                    remarkPlugins={[remarkGfm]}
                  >
                    {founder.description}
                  </ReactMarkdown>
                  <div className="founder-socials">
                    {founder.socialLinks.map((social) => (
                      <a
                        className="founder-socials-icon"
                        key={social.id}
                        href={social.url}
                        rel="noreferrer"
                      >
                        <img
                          src={withAssetPrefix(
                            `/images/about-socials/${social.socialIcon}.svg`
                          )}
                          alt={`${social.socialIcon} logo`}
                        />
                      </a>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
          <section
            className="about-section mission-statement-section js-about-section"
            id={data.about.missionStatementSlug}
          >
            <h1>{data.about.missionStatementTitle}</h1>
            <ReactMarkdown
              className="about-markdown"
              remarkPlugins={[remarkGfm]}
            >
              {data.about.missionStatementBody}
            </ReactMarkdown>
          </section>
          <section
            className="about-section press-section js-about-section"
            id={data.about.pressLinksSlug}
          >
            <h1>{data.about.pressLinksTitle}</h1>
            <div className="press-container">
              {data.about.pressLink.map((press) => (
                <a
                  href={press.url}
                  target="_blank"
                  rel="noreferrer"
                  key={press.id}
                  className="press-item"
                >
                  <span className="press-tag">{press.tag}</span>
                  <h5>{press.title}</h5>
                </a>
              ))}
            </div>
          </section>
        </section>
        <SecondaryClouds />
      </main>
    </Layout>
  );
};

export default Pricing;

export const query = graphql`
  query AboutUs($language: String!) {
    about: datoCmsAboutUsPage(locale: { eq: $language }) {
      aboutUsTitle
      aboutUsSlug
      aboutUsBody
      foundersTitle
      foundersSlug
      founder {
        image {
          url(imgixParams: { auto: "enhance", q: 100, w: "600" })
          alt
          width
          height
        }
        role
        name
        description
        id
        socialLinks {
          url
          socialIcon
          id
        }
      }
      missionStatementTitle
      missionStatementSlug
      missionStatementBody
      pressLinksTitle
      pressLinksSlug
      pressLink {
        url
        id
        tag
        title
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
